
import ContractsService from "@/modules/contracts/contracts-service";

export default {
    create(payload) {
        return new Promise((resolve, reject) => {
            ContractsService.create(payload).then(response => {
                resolve({
                    message: "Contracts criada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao criar Contracts",
                    success: false,
                    error
                });
            })
        })
    },
    update(payload) {
        return new Promise((resolve, reject) => {
            ContractsService.update(payload.id,payload).then(response => {
                resolve({
                    message: "Contracts atualizada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao atualizar Contracts",
                    success: false,
                    error
                });
            })
        })
    },
    remove(id) {
        return new Promise((resolve, reject) => {
            ContractsService.remove(id).then(response => {
                resolve({
                    message: "Contracts removida com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao remover Contracts",
                    success: false,
                    error
                });
            })
        })
    },
    delete(id) {
        return new Promise((resolve, reject) => {
            ContractsService.delete(id).then(response => {
                resolve({
                    message: "Contracts deletada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao deletar Contracts",
                    success: false,
                    error
                });
            })
        })
    },
    get(id) {
        return new Promise((resolve, reject) => {
            ContractsService.get(id).then(response => {
                resolve({
                    message: "Contracts encontrada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar Contracts",
                    success: false,
                    error
                });
            })
        })
    },
    paginate(query) {
        return new Promise((resolve, reject) => {
            ContractsService.paginate(query).then(response => {
                resolve({
                    message: "Contracts encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar Contracts",
                    success: false,
                    error
                });
            })
        })
    },
    all() {
        return new Promise((resolve, reject) => {
            ContractsService.all().then(response => {
                resolve({
                    message: "Contracts encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar Contracts",
                    success: false,
                    error
                });
            })
        })
    }
}
